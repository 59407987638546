var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"manage-space-page"}},[_c('toolbar-back-button-component',{attrs:{"title":_vm.$t('manage_space_page.space_information')}}),_c('div',{staticClass:"pa-4"},[_c('p',{staticClass:"font-weight-bold mt-2"},[_vm._v(_vm._s(_vm.$t('manage_space_page.space_information')))]),_c('div',{staticClass:"mb-2",attrs:{"id":"company-name"}},[_c('div',{staticClass:"white-text-field"},[_c('v-text-field',{attrs:{"disabled":!_vm.userHasAccess('BASE', _vm.role_admin),"label":_vm.$t('manage_space_page.company_name'),"loading":_vm.loading,"data-cy":"company-name","filled":"","hide-details":""},on:{"click":function($event){_vm.openDialogEdition(
                            _vm.$t('manage_space_page.modify_company_name'),
                            _vm.$t('manage_space_page.company_name'),
                            _vm.space.company_name,
                            'company_name'
                        )}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}]),model:{value:(_vm.space.company_name),callback:function ($$v) {_vm.$set(_vm.space, "company_name", $$v)},expression:"space.company_name"}})],1)]),_c('div',{staticClass:"mb-2",attrs:{"id":"client-name-space"}},[_c('div',{staticClass:"white-text-field"},[_c('v-text-field',{attrs:{"disabled":!_vm.userHasAccess('BASE', _vm.role_admin),"label":_vm.$t('manage_space_page.client_space_name'),"loading":_vm.loading,"data-cy":"client-space-name","filled":"","hide-details":"","readonly":""},on:{"click":function($event){_vm.openDialogEdition(
                            _vm.$t('manage_space_page.modify_client_space_name'),
                            _vm.$t('manage_space_page.client_space_name'),
                            _vm.space.client_space_name,
                            'client_space_name'
                        )}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}]),model:{value:(_vm.space.client_space_name),callback:function ($$v) {_vm.$set(_vm.space, "client_space_name", $$v)},expression:"space.client_space_name"}})],1)]),_c('div',{staticClass:"mb-2",attrs:{"id":"siret"}},[_c('div',{staticClass:"white-text-field"},[_c('v-text-field',{attrs:{"disabled":!_vm.userHasAccess('BASE', _vm.role_admin),"label":_vm.$t('manage_space_page.siret_siren'),"loading":_vm.loading,"data-cy":"siret-siren","filled":"","hide-details":"","readonly":""},on:{"click":function($event){_vm.openDialogEdition(
                            _vm.$t('manage_space_page.modify_siret_siren'),
                            _vm.$t('manage_space_page.siret_siren'),
                            _vm.space.siret,
                            'siret'
                        )}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}]),model:{value:(_vm.space.siret),callback:function ($$v) {_vm.$set(_vm.space, "siret", $$v)},expression:"space.siret"}})],1)]),(_vm.userHasAccess('BASE', _vm.role_admin))?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_edition),callback:function ($$v) {_vm.dialog_edition=$$v},expression:"dialog_edition"}},[_c('v-card',{attrs:{"data-cy":"dialog-edition"}},[_c('v-card-title',{staticClass:"mb-0"},[_c('p',{staticClass:"font-weight-bold font-18 text-uppercase"},[_vm._v(_vm._s(_vm.title_dialog_edition))])]),_c('v-form',{ref:"form_space"},[_c('v-card-text',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"label":_vm.label_input_edition,"rules":[_vm.rules.required],"data-cy":"edit-space-input","filled":""},model:{value:(_vm.property_value_to_edit),callback:function ($$v) {_vm.property_value_to_edit=$$v},expression:"property_value_to_edit"}})],1),_c('v-card-actions',{staticClass:"justify-end pb-4"},[_c('v-btn',{attrs:{"color":"accent","data-cy":"cancel-btn","text":""},on:{"click":function($event){return _vm.closeDialogEdition()}}},[_c('span',{staticClass:"font-color-light font-weight-bold"},[_vm._v(_vm._s(_vm.$t('global.cancel')))])]),_c('v-btn',{staticClass:"btn-secondary-loader",attrs:{"disabled":!_vm.valid,"loading":_vm.loading_edit,"color":"secondary","data-cy":"submit-btn","text":""},on:{"click":function($event){return _vm.updateSpace()}}},[_c('span',{class:_vm.valid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'},[_vm._v(" "+_vm._s(_vm.$t('global.validate'))+" ")])])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }